.customFooter {
  justify-content: center;
}

.customFooter__divider {
  width: 65%;
  border-color: #cccccc;
}

.customFooter__links {
  justify-content: center;
  width: 80%;
  margin-bottom: 5vh;
}

.footerLink {
  padding: 1vh calc(1.5vmin + 5px);
  font-size: calc(1.5vmin + 5px);
}

@media only screen and (max-width: 500px) {
  .customFooter__divider {
    width: 90%;
    border-color: #cccccc;
  }
}
