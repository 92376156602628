/* * {
  border: 1px solid grey;
} */

.GraphContainer {
  /*required by Chart.js*/
  position: relative;
  width: 40%;
  height: 40%;
  border: 1px solid grey;
}

.content {
  //margin: 15vh 0 10vh 0;
  /* border: 1px solid grey; */
}

.section {
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.graphContainer {
  width: 40%;
  height: 40vh;
}

.mainText {
  width: 40%;
}

/* .tabs {
  display: flex;
  justify-content: center;
} */

.sections {
  display: flex;
  justify-content: space-around;
  padding-top: 40px;
  /* padding-bottom: 80px; */
}

.section-1 {
  width: 30%;
}

.section-2 {
  width: 70%;
}

.section__title {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

/* .Collapsible {
  width: 100%;
} */

.Collapsible {
  margin: 30px 0;
}

.Collapsible__trigger {
  background-color: #dddddd;
  cursor: pointer;

  display: inline-block;
  width: 100%;
  padding: 10px;
}

.Collapsible__contentInner {
  padding: 0 10px;
}

.more-info {
  color: #1b9fff;
  float: right;
}

.collapsible-text {
  font-size: 1.1rem;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}
