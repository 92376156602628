* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: #1b9fff;
  transition: color 0.15s;
}

button {
  outline: none;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

#page-container {
  margin: 0% 15%;
  position: relative;
  min-height: 99vh;
}

#content-wrap {
  padding-top: 3rem + 40pt;
  padding-bottom: 2.5rem; /* Footer height */
  margin-top: 15vh;
  /* border: solid 2px pink; */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
}

@media only screen and (max-width: 900px) {
  #page-container {
    margin: 0% 5%;
  }
}

@media only screen and (max-width: 800px) {
  #content-wrap {
    margin-top: 5vh;
  }
}

@media only screen and (max-width: 600px) {
  #page-container {
    margin: 0% 5%;
  }
}
