.disclaimer{
  width: 80%;
  margin-bottom: 50px;
  font-size: calc(10px + 1vmin);
}

.panss-question-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.panss-question-title{
  max-width: 300px;
  font-size: calc(10px + 1.5vmin);
}

.panss-question-input-number{
  width: 100px;
  height: 30px;
  border-radius: 5px;
  border: 0.5px solid grey;
  font-size: calc(10px + 1.5vmin);
  padding-left: 10px;
}


@media only screen and (max-width: 600px){
  .panss-question-title{
    max-width: 150px;
  }
  .panss-question-input-number{
    width: 50px;
  }
}
