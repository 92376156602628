#DrugDecider {
  text-decoration: none;
  font-size: calc(3vmin + 20px);
}

.customHeader {
  padding-top: 50px;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
}

.logoGroup {
  width: 30vw;
  align-items: center;
  flex-wrap: nowrap;
}

.ddLogo {
  height: 50px;
  margin: 0 10px;
}

.headerLink {
  padding: calc(1vmin + 2px) calc(2vmin + 5px);
  font-size: calc(15px + 0.5vw);
}

@media only screen and (max-width: 800px) {
  .logoGroup {
    width: 100%;
    justify-content: center;
    margin-bottom: 2vh;
  }
  .navBar {
    width: 100%;
    justify-content: center;
  }
}
